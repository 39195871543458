
::-webkit-scrollbar {
    display: none;
}
nav{
    box-shadow: 1px 2px 13px 2px black;
}
.flarge{
    font-size: 25px;
  }
  
  @media screen and (min-width: 992px) {
    #navbarNavDropdown{
     justify-content: center;
    }

  }
 
button{
  border-radius: 10px;
}

.heading{
  text-decoration: underline;
  -webkit-text-decoration: bold;
          text-decoration: bold;
}

.ml{
  margin-right: 1rem !important;
  margin-top: 8px;
}
